import { giphyUrl } from '@/app/util/env'
import { IChannel } from '@giphy/js-types'
import { getCreator, setGADataLayer } from 'analytics'
import styled from 'styled-components'
import { IProfilePowerUser } from 'types'

type SocialLink = {
    type: string
    url: string | undefined
}

const getSocialLinks = ({
    facebook_url,
    twitter_url,
    tumblr_url,
    instagram_url,
    tiktok_url,
    youtube_url,
}: IProfilePowerUser): SocialLink[] => {
    const social = [
        { type: 'facebook', url: facebook_url },
        { type: 'twitter', url: twitter_url },
        { type: 'tumblr', url: tumblr_url },
        { type: 'instagram', url: instagram_url },
        { type: 'tiktok', url: tiktok_url },
        { type: 'youtube', url: youtube_url },
    ]
    // changed this to js native filter bc typescript expects lodash filter to return a number in some cases
    return social.filter((item) => item.url)
}

const Links = styled.div`
    display: flex;
`

const trackGA4NavigationEvent = (url: string | undefined, type: string) => {
    const data = {
        event: 'creator_social_nav',
        options: {
            eventInfo: {
                navElement: 'creator',
                navSubelement: 'creator social',
                link: url,
            },
            creator: {
                ...getCreator(),
                socialLink: type,
            },
        },
    }
    setGADataLayer(data)
}

const SocialButtons = ({ channel }: { channel: IChannel }) => {
    const links = getSocialLinks(channel.user as IProfilePowerUser)
    return links.length > 0 ? (
        <>
            <div className="text-giphyLightGrey hidden text-xs font-semibold md:block">Follow on:</div>
            <Links>
                {links.map(({ type, url }) => (
                    <a
                        onClick={() => trackGA4NavigationEvent(url, type)}
                        href={url}
                        key={type}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={`open user profile in ${type}`}
                    >
                        <img
                            suppressHydrationWarning
                            src={`${giphyUrl}/static/img/soc-icons/${type}@2x.png`}
                            alt={type}
                            className="size-[44px] md:size-[30px]"
                        />
                    </a>
                ))}
            </Links>
        </>
    ) : null
}
export default SocialButtons
