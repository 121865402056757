import { SearchIcon } from '@/app/icons/navigation'
import * as colors from '@giphy/colors'
import { IChannel, IUser } from '@giphy/js-types'
import { useState } from 'react'
import styled from 'styled-components'

const IMAGE_TYPES = ['gif', 'gifs', 'sticker', 'stickers']

const SearchInput = styled.input`
    background: ${colors.giphyBlack};
    border: 2px solid ${colors.giphyDarkGrey};
    box-sizing: border-box;
    color: ${colors.giphyWhite};
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding-right: 50px;
    width: 100%;
    padding-left: 6px;
`

type Props = {
    user?: IUser
    className?: string
    channel: IChannel
}
const endsWithWords = (fullString = '', words: string[] = []) => {
    if (!words.length) return false
    const regExp = new RegExp(`(${words.join('|')})$`, `gi`)
    const matches = fullString.trim().match(regExp)
    return matches ? matches[matches.length - 1] : false
}

const Search = ({ user, channel, className }: Props) => {
    let displayName
    let shortDisplayName
    let searchSlug

    const [inputValue, setInputValue] = useState<string>('')

    if (user) {
        displayName = user.display_name
        shortDisplayName = user.display_name
        searchSlug = user.username
    } else {
        const parent = channel.ancestors[0]
        displayName = parent?.display_name && channel.display_name
        shortDisplayName = parent?.short_display_name && channel.short_display_name
        searchSlug = parent?.slug && channel.slug
    }
    const imageType = channel.content_type === 'sticker' ? 'Stickers' : 'GIFs'
    return (
        <div className={className}>
            <form
                action={`/search/@${searchSlug}-${encodeURIComponent(inputValue)}`}
                className="bg-giphyBlack relative flex h-[40px]"
            >
                <SearchInput
                    required
                    placeholder={`Search ${shortDisplayName || displayName} ${
                        endsWithWords(shortDisplayName || displayName, IMAGE_TYPES) ? '' : imageType
                    }`}
                    onChange={(e) => setInputValue(e.target.value)}
                    value={inputValue || ''}
                />
                <div className="bg-giphyDarkGrey absolute bottom-0 right-0 top-0 flex w-[40px] items-center justify-center">
                    <SearchIcon className="fill-giphyLightestGrey size-5" />
                </div>
            </form>
        </div>
    )
}

export default Search
