'use client'
import Avatar from '@/components/avatar'
import Username from '@/components/username'
import { NexaBlack } from '@/styles/fonts'
import { IChannel } from '@giphy/js-types'
import Link from 'next/link'
import { ReactNode } from 'react'

type Props = { channel: IChannel; title: ReactNode }

const Breadcrumbs = ({ channel }: { channel: IChannel }) => {
    const crumbs = (channel.ancestors || []).map((ancestor, index) => {
        const name = ancestor.short_display_name || ancestor.display_name
        return (
            <div key={ancestor.id} className="flex items-center gap-2">
                {index !== 0 && <span>/</span>}
                <Link href={ancestor.url}>
                    <h5>{name}</h5>
                </Link>
            </div>
        )
    })
    return <div className="text-giphyLightGrey flex gap-2">{crumbs}</div>
}

const ChannelPageHeader = ({ channel, title }: Props) => (
    <>
        <Breadcrumbs channel={channel} />
        <div className="mr-2 flex items-center gap-3">
            <Avatar user={channel.user} className="object-cover md:hidden" />
            <div>
                <h2 className={`mb-1 text-2xl md:text-4xl ${NexaBlack.className}`}>{title}</h2>
                <Username user={channel.user} className="text-giphyLightGrey" />
            </div>
        </div>
    </>
)

export default ChannelPageHeader
