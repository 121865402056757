'use client'

import Avatar from '@/components/avatar'
import Button from '@/components/button'
import ChannelTags from '@/components/carousel/channel-tags'
import UAParserContext from '@/context/ua-parser'
import UserContext from '@/context/user-context'
import { IChannel } from '@giphy/js-types'
import { usePathname } from 'next/navigation'
import { ReactNode, useContext } from 'react'
import { IProfilePowerUser } from 'types'
import { UserAnalytics } from '../analytics-api'
import ChannelAnalyticsOverview from './analytics'
import ChannelPageHeader from './page-header'
import Search from './search'
import SocialButtons from './social-buttons'

function getHostname(primary_site: string) {
    try {
        return new URL(primary_site).hostname
    } catch (e) {}
    return primary_site
}

type Props = {
    channel: IChannel
    analytics?: UserAnalytics
    display_name: ReactNode
}
const ChannelInfo = ({ channel, analytics, display_name }: Props) => {
    const { deviceType } = useContext(UAParserContext)
    const { user } = useContext(UserContext)
    const pathname = usePathname()
    const {
        user: { about_bio, primary_site },
    } = channel
    const showDashboard = user?.permissions.dashboard_analytics && channel.user.id === user.id
    return (
        <div className="flex flex-col gap-3 px-1.5 py-px md:w-[208px] md:px-0">
            {deviceType === 'desktop' ? (
                <Avatar user={channel.user} size={208} />
            ) : (
                <ChannelPageHeader channel={channel} title={display_name} />
            )}
            {analytics && <ChannelAnalyticsOverview analytics={analytics} />}
            {showDashboard && (
                <div className="hidden md:flex md:gap-2">
                    <div className="ss-barchart text-giphyGreen"></div>
                    <a href="/dashboard" className="text-sm font-semibold">
                        Visit your Dashboard
                    </a>
                </div>
            )}
            <div className="flex flex-col gap-2 overflow-hidden">
                {about_bio && (
                    <p className="md:text-giphyLightGrey my-2.5 whitespace-pre-wrap md:text-sm">{about_bio}</p>
                )}
                {primary_site && (
                    <a
                        href={primary_site}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-giphyLightGrey md:text-giphyWhite overflow-hidden text-ellipsis text-sm md:font-semibold"
                    >
                        {getHostname(primary_site)}
                    </a>
                )}
                <SocialButtons channel={channel} />
                {(channel.user as IProfilePowerUser).is_freelance && (
                    <>
                        <Button
                            className="hidden md:block"
                            // TODO - this opens up a panel with the hire me form
                            href={user ? '/' : `/login?next=${encodeURIComponent(`${pathname}?hire=true`)}`}
                            rel="nofollow"
                        >
                            <div className="flex items-center justify-center gap-2">
                                <span className="ss-mail" style={{ height: 19 }} />
                                Hire me
                            </div>
                        </Button>
                    </>
                )}
                <hr className="bg-giphyLightCharcoal h-px w-full" />
                <Search channel={channel} user={channel.user} className="hidden md:block" />
            </div>
            <ChannelTags channel={channel} className="items-start md:flex md:flex-col md:gap-2 md:space-x-0" />
        </div>
    )
}

export default ChannelInfo
