import { SVGAttributes } from 'react'

const LockIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6667 20C15.0347 20 15.349 19.8779 15.6094 19.6338C15.8698 19.3896 16 19.0951 16 18.75V10C16 9.65495 15.8698 9.36035 15.6094 9.11621C15.349 8.87207 15.0347 8.75 14.6667 8.75H13.3333V5C13.3333 4.32292 13.1927 3.67676 12.9115 3.06152C12.6302 2.44629 12.25 1.91406 11.7708 1.46484C11.2917 1.01562 10.724 0.659179 10.0677 0.395507C9.41146 0.131835 8.72222 0 8 0C7.27778 0 6.58854 0.131835 5.93229 0.395507C5.27604 0.659179 4.70833 1.01562 4.22917 1.46484C3.75 1.91406 3.36979 2.44629 3.08854 3.06152C2.80729 3.67676 2.66667 4.32292 2.66667 5V8.75H1.33333C0.965277 8.75 0.651042 8.87207 0.390625 9.11621C0.130208 9.36035 0 9.65495 0 10V18.75C0 19.0951 0.130208 19.3896 0.390625 19.6338C0.651042 19.8779 0.965277 20 1.33333 20H14.6667ZM10.6667 8.75H5.33333V5C5.33333 4.3099 5.59375 3.7207 6.11458 3.23242C6.63542 2.74414 7.26389 2.5 8 2.5C8.73611 2.5 9.36458 2.74414 9.88542 3.23242C10.4062 3.7207 10.6667 4.3099 10.6667 5V8.75Z"
        />
    </svg>
)
export default LockIcon
