import { UserAnalytics } from '@/app/(site)/[username]/analytics-api'
import LockIcon from '@/app/icons/lock'
import { NexaBlack } from '@/styles/fonts'
import { giphyRed } from '@giphy/colors'
import { roundWithSuffix } from 'utils/src/number/display-text'

type Props = {
    analytics: UserAnalytics
}

const Stat = ({ count, label }: { count: number; label: string }) => (
    <div className="flex items-baseline gap-2 font-extrabold md:flex-col md:gap-0 md:text-xl">
        <div className={`text-giphyWhite text-md font-light ${NexaBlack.className}`}>{roundWithSuffix(count)}</div>
        <div className={'text-giphyLightGrey text-sm'}>{label}</div>
    </div>
)

const ChannelAnalyticsOverview = ({ analytics: { uploadCount, isPublic, viewCount } }: Props) => {
    if (!uploadCount || uploadCount <= 0) return null
    return (
        <>
            <div className="flex gap-2 md:gap-4">
                <Stat count={uploadCount} label={'GIF Uploads'} />
                <div className="bg-giphyCharcoal h-full w-px" />
                <Stat count={viewCount} label={'GIF Views'} />
            </div>
            {!isPublic && (
                <div className="text-giphyCharcoal flex items-baseline gap-1">
                    <p>Your metrics are private</p>
                    <div className="bg-giphyDarkCharcoal flex size-4 items-center justify-center">
                        <LockIcon color={giphyRed} height={8} />
                    </div>
                </div>
            )}
        </>
    )
}

export default ChannelAnalyticsOverview
