'use client'
import { cn } from '@/util/cn'
import { giphyEncode } from '@/util/url'
import { IChannel } from '@giphy/js-types'
import Link from 'next/link'
import styled from 'styled-components'
import TagPills_ from 'ui/src/components/tag-pill'
import { CarouselContainer } from './sc'

const TagPills = styled(TagPills_)`
    animation-name: unset;
`
const ChannelTags = ({ channel, className }: { channel: IChannel; className?: string }) => {
    return channel.tags ? (
        <CarouselContainer className={cn('space-x-1.5', className)}>
            {channel.tags?.map(({ tag }) => (
                <Link href={`/${channel.user.username}/tagged/${encodeURIComponent(giphyEncode(tag))}`} key={tag}>
                    <TagPills text={`#${tag}`} />
                </Link>
            ))}
        </CarouselContainer>
    ) : null
}
export default ChannelTags
