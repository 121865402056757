'use client'
import { Gif } from '@giphy/react-components'
import styled from 'styled-components'

export const CarouselContainer = styled.div<{ isSticker?: boolean }>`
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    overflow-y: visible;
    white-space: nowrap;
    .${Gif.className} {
        margin-right: 4px;
        display: inline-block;
    }
    .${Gif.imgClassName} {
        object-fit: cover;
    }
    [data-giphy-is-sticker='true'] {
        .${Gif.imgClassName} {
            object-fit: contain;
        }
    }
`
